import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "@/components/Layout";
import News from "@/components/Carts/NewsCart";
import HomeNews from "@/components/Home/HomeNewsBuilder";
import { SubPage } from "@/components/subPage";

import usePages from "@/hooks/usePages";
import usePosts from "@/hooks/usePosts";
import convertSlug from "@/utils/convertSlug";
import { CustomRenderer } from "@/utils/CustomRenderer";
import { setGlobalState, useGlobalState } from "@/state";

const Home = ({ data, ...props }: any) => {
  const pages = usePages();
  var posts = usePosts();

  const mnPages = pages?.filter(
    (page: any) => page?.localization.code === "mn"
  );

  const enPages = pages?.filter(
    (page: any) => page?.localization.code === "en"
  );

  const chPages = pages?.filter(
    (page: any) => page?.localization.code === "ch"
  );

  // Will set Global State based on localStorage. If localStorage is not defined, it will set the locale to Mongolian.
  useEffect(() => {
    // localStorage.hasOwnProperty("locale")
    //   ? localStorage.getItem("locale") == "en"
    //     ? setGlobalState("locale", "en")
    //     : setGlobalState("locale", "mn")
    //   : localStorage.setItem("locale", "mn");
  });

  const [bro] = useGlobalState("locale");

  // console.log("location", location.pathname);

  // location != null && location?.pathname == "/" && bro == "en"
  //   ? window.location.replace("en")
  //   : null;

  const [locale] = useGlobalState("locale");

  // Filter Mongolian pages
  function findMongolianPage(el: any) {
    return el.localization.code == "mn" && el.slug == data.cms.page.slug;
  }

  function findByLocale(el: any, locale = "en") {
    return el.localization.code == locale && el.slug == data.cms.page.slug;
  }

  // Filter posts by locale
  function findLocalePost(el: any) {
    return el.localization.code == locale;
  }

  posts = posts.filter(findLocalePost);

  // Set both Mongolian and English page
  var pageEN = data.cms.page;

  var page = pages?.find((el: any) => findByLocale(el, locale)) || pageEN;
  // Check if the Mongolian page is published. If not it will return the English page
  if (page.status == "draft") {
    page = pageEN;
  }

  const { settings } = props.pageContext;

  const { content, childPage, title, slug } = page;

  return (
    <>
      <Layout title={title} settings={settings}>
        <div className=" bg-lightGray">
          {(slug === "home" || slug == "") && (
            <div>
              <div className="bg-theme h-[1px]"></div>
              <div className="relative">
                <HomeNews posts={posts} />
              </div>
            </div>
          )}

          <CustomRenderer document={content.document} />

          {convertSlug(title) === "news" && (
            <article className="xl:container">
              <div className="px-[5%] relative lg:px-0 mt-[2rem]">
                <section className="columns-1 sm:columns-2 lg:columns-3 min-h-full break-after-avoid break-before-avoid	break-inside-avoid	">
                  {posts.map((post: any) => (
                    <News key={post.id} data={post} />
                  ))}
                </section>
              </div>
            </article>
          )}
          {childPage.length !== 0 && <SubPage props={childPage} />}
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query Home($id: ID) {
    cms {
      page(where: { id: $id }) {
        title
        id
        slug
        content {
          document(hydrateRelationships: true)
        }
        localization {
          code
        }
        childPage {
          id
          title
          content {
            document(hydrateRelationships: true)
          }
        }
      }
    }
  }
`;
export default Home;
