import * as React from "react";
import { Link } from "gatsby";
import { PostType } from "@/types";
import moment from "moment";
import convertSlug from "../../utils/convertSlug";
import { KeystoneImage } from "@/components/gatsby-image-keystone";
import { CustomRenderer } from "@/utils/CustomRenderer";
import { useIntl } from "gatsby-plugin-intl";

interface IProps {
  data: PostType;
}

const News = ({ data }: IProps) => {
  const { title, content, featureImage, publishDate } = data;
  const { formatMessage: fm } = useIntl();

  return (
    <div className="min-h-full mx-auto break-inside-avoid max-w-[413px] w-full px-[20px] pb-[40px]">
      <div className="shadow-3xl">
        {featureImage && (
          <KeystoneImage
            baseUrl="smp"
            image={{
              alt: featureImage.id,
              width: 413,
              height: featureImage.height * (413 / featureImage.width),
              key: `${featureImage.id}.${featureImage.extension}`,
            }}
            layout="fullWidth"
            alt={featureImage.id}
          />
        )}
        <div className="px-[40px] pb-[48px] pt-[40px]">
          <h3 className="relative mb-2 border-l-4 border-theme pl-[12px] md:text-[14px] text-[12px] font-[700] uppercase leading-[26px] tracking-[0.5px]">
            {" "}
            {fm({ id: "newsTitle" })} / {moment(publishDate).format("LL")}
          </h3>
          <Link to={`/news/${convertSlug(title)}`}>
            <div className="md:text-[24px] text-[15px] font-[700] text-dark transition-colors duration-300 ease-out hover:text-theme cursor-pointer">
              {title}
            </div>
          </Link>
          <div className="mt-2 text-[12px] md:text-[16px] font-[700] text-darkGray">
            <CustomRenderer document={content.document} />
          </div>
          <Link to={`/news/${convertSlug(title)}`}>
            <button
              name={title}
              className=" relative mt-6 text-dark text-[12px] md:text-[16px] duration-500 before:absolute before:right-0 before:bottom-0 before:h-[4px] before:w-full before:bg-theme before:transition-width hover:before:left-0 hover:before:w-0 "
            >
              Read article
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default News;
