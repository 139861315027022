import { useStaticQuery, graphql } from "gatsby";

const usePages = () => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        pages {
          content {
            document(hydrateRelationships: true)
          }
          localization {
            code
          }
          title
          slug
          id
          status
          childPage {
            id
            title
            content {
              document(hydrateRelationships: true)
            }
          }
        }
      }
    }
  `);
  const { pages } = data?.cms;
  return pages;
};

export default usePages;
