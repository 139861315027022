import React from "react";
import { Link } from "gatsby";

// import usePosts from "@/hooks/usePosts";
import convertSlug from "@/utils/convertSlug";

import { IoIosArrowForward } from "react-icons/io";
import { KeystoneImage } from "@/components/gatsby-image-keystone";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Scrollbar, Mousewheel } from "swiper";
import "swiper/css/scrollbar";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./styles.css";
import { useIntl } from "gatsby-plugin-intl";
const Builder = ({ posts }: any) => {
  const { formatMessage: fm } = useIntl();

  const articleBuilder = posts.map((post: any) => {
    return (
      <SwiperSlide key={post.id}>
        <div
          key={post.id}
          className="text-left mb-[1rem] px-[1rem] max-w-[20rem] min-w-[20rem]"
        >
          <div className="flex !justify-between gap-3 min-h-[72px] hover:border-t-theme ease-in-out duration-300 border-t-white border-t-[5px] py-[1rem]">
            {/* Title */}
            <div className="">
              <Link
                to={`/news/${convertSlug(post.title)}`}
                className="font-[700] duration-300 ease-in-out"
              >
                {/* <p className="font-[700] leading-[30px]">{article.title}</p> */}
                {/* Title */}
                <p className="font-[700] text-[14px]">{post.title}</p>
              </Link>
            </div>

            {/* Image */}
            <div className="relative h-max bg-theme p-[2px]">
              <a href="relative group" aria-label={post.title}>
                {post.featureImage && (
                  <KeystoneImage
                    baseUrl="smp"
                    image={{
                      alt: post.featureImage.id,
                      width: 72,
                      height: 72,
                      key: `${post.featureImage.id}.${post.featureImage.extension}`,
                    }}
                    layout="fixed"
                    alt={post.featureImage.id}
                  />
                )}
              </a>
            </div>
          </div>
          <Link to={`/news/${convertSlug(post.title)}`}>
            <div className="relative group flex duration-300 ease-in-out">
              <p className="text-[12px] text-[#585858] font-[700] group-hover:text-theme">
                {fm({ id: "newsReadArticle" })}
              </p>
              <div className="my-auto">
                <IoIosArrowForward
                  size={10}
                  className="group-hover:text-theme"
                />
              </div>
            </div>
          </Link>
        </div>
      </SwiperSlide>
    );
  });

  return (
    // <div className="flex bg-white overflow-x-scroll"></div>

    <Swiper
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        700: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
        1300: {
          slidesPerView: 4,
        },
        1450: {
          slidesPerView: 5,
        },
        1784: {
          slidesPerView: 6,
        },
      }}
      slidesPerView={4}
      direction={"horizontal"}
      freeMode={true}
      mousewheel={true}
      modules={[FreeMode, Mousewheel, Scrollbar]}
      className="swiper"
      scrollbar={{
        draggable: true,
        dragSize: 170,
      }}
    >
      {articleBuilder}
    </Swiper>
  );
};

export default Builder;
